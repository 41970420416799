// Triple hyphens on lines 1 and 2 are required by Jekyll in order to
// process SCSS into CSS.
//
// Note that your code editor may syntax highlight the first import statement
// below as being in an error state due to the hyphens above which are part of
// Jekyll syntax and not SCSS syntax. An invalid syntax error, especially on
// the first meaningful line of code, might be a false positive.

// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme/_config';

.home-banner {
  max-height: 1080*$pxToRem;

  .logo {
    width: 536*$pxToRem;
    height: 292*$pxToRem;

    @include media-mobile-only {
      width: 70%;
      height: auto;
      margin: auto;
    }
  }
}
@include media-mobile-only {
  .home-banner {
    height: 60vh;
    max-height: 60vh;
  }
}

.banner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $color-black;
	max-width: 100%;
	color: $color-white;
	.banner__content {
		position: absolute;
		z-index: 5;
	}
	h1 {
		margin-top: 0.25rem;
		margin-bottom: 0;
		+ {
			.hr {
				margin-top: 1rem;
			}
		}
    img {
      height: 350*$pxToRem;
      @include media-mobile-only {
        height: 200*$pxToRem;
        width: 131*$pxToRem;
      }
    }
    span {
      font-size: 1.313rem;
      display: block;
      text-transform: uppercase;
    }
	}
	.hr {
		margin: 0 auto;
		max-width: 22.5rem;
		+ {
			nav {
				margin-top: 0.625rem;
			}
		}
	}
	nav {
		ul {
			list-style: none;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			font-size: 1.3125rem;
			text-transform: uppercase;
			gap: 1.6875rem;
			li {
				position: relative;
				&:not(:last-child) {
					&::before {
						display: block;
						position: absolute;
						right: -0.9375rem;
						top: 1.3125rem;
						content: "|";
						width: 0.1875rem;
						height: 0.1875rem;
						// background: #ffffff;
						// border-radius: 100%;
					}
				}
				a {
					font-size: 1.3125rem;
					font-weight: 500;
					letter-spacing: 0.08em;
					line-height: 1.7142857143;
					text-transform: uppercase;
					display: block;
					padding: 0.3125rem 0.25rem;
				}
			}
		}
		ol {
			list-style: none;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			font-size: 1.3125rem;
			text-transform: uppercase;
			gap: 1.6875rem;
			li {
				position: relative;
				&:not(:last-child) {
					&::before {
            content: "|";
            position: absolute;
						display: block;
            right: -18*$pxToRem;
            top: 5*$pxToRem;
					}
				}
				a {
					font-size: 1.3125rem;
					font-weight: 500;
					letter-spacing: 0.08em;
					line-height: 1.7142857143;
					text-transform: uppercase;
					display: block;
					padding: 0.3125rem 0.25rem;
				}
			}
		}
	}
}
.banner--short {
	height: auto;
	max-height: auto;
	padding-top: 7.0625rem;
	padding-bottom: 3.5rem;
}

.banner-buttons {
  z-index: 5;
  width: 100%;
}


@include media-mobile-only {
	.banner {
		margin-top: 60px;
		nav {
			ul {
				li {
					a {
						line-height: 1.25;
						font-size: 1.125rem;
					}
					&:not(:last-child) {
						&::before {
							top: 1*$pxToRem;
						}
					}
				}
			}
			ol {
				li {
					a {
						line-height: 1.25;
						font-size: 1.125rem;
					}
					&:not(:last-child) {
						&::before {
							top: 1*$pxToRem;
						}
					}
				}
			}
		}
	}
}


#home_slides {
	position: relative;
	height: 100vh;
  width: 100%;
	padding: 0px;
	margin: 0px;
	list-style-type: none;
  overflow: hidden;
}
.slide {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 1;
	transition: opacity 1s;
	font-size: 40px;
	padding: 40px;
	box-sizing: border-box;
	background: #333;
	color: #fff;
	background-size: cover;
	&:nth-of-type(1) {
		background-image: url('/wp-content/uploads/2022/11/bg-banner-home-1@2x-scaled.jpg');
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
	&:nth-of-type(2) {
		background-image: url('/wp-content/uploads/2022/11/bg-banner-home-2@2x-scaled.jpg');
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
	&:nth-of-type(3) {
		background-image: url('/wp-content/uploads/2022/11/bg-banner-home-3@2x-scaled.jpg');
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
  &.active {
  	opacity: 1;
  	z-index: 2;
    animation: zoomOut 4s;
    animation-timing-function: cubic-bezier(.43,1.06,.46,.96);
  }

  @keyframes zoomOut {
    0% {
        opacity: 0.1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

  @include media-mobile-only {
    &:nth-of-type(1) {
      background-position-x: 30%;
    }
    &:nth-of-type(2) {
      background-position-x: 17%;
    }
    &:nth-of-type(3) {
      background-position-x: 80%;
    }
  }
}



.bottom-left {
  position: absolute;
  left: 7.5vw;
  bottom: 3.375rem;
  z-index: 5;
}

.bottom-right {
  position: absolute;
  right: 7.5vw;
  bottom: 3.375rem;
  z-index: 5;
}

@include media-mobile-only {
  .bottom-left, .bottom-right {
    .button-round {
      padding: 5*$pxToRem 20*$pxToRem;
    }
  }
}


.border-gray {
  img {
    border: 1px solid #DBDBDB;
  }
}

.ac-award {
  width: 219px;
  @include media-mobile-only {
    padding: 0 0 0 20px;
    box-sizing: border-box;
    margin-bottom: 24px;
  }
}
.ac-procedures {
  width: 232px;
}

.rating {
  text-align: center;
  .realself-rating svg {
    width: 109px;
  }
  .healthgrades-rating svg {
    width: 157px;
  }
  .facebook-rating svg {
    width: 124px;
  }
  .google-rating svg {
    width: 92px;
  }
  .yelp-rating svg {
    width: 87px;
  }
}

.max-width {
  max-width: 1640*$pxToRem;
  margin: auto;
  position: relative;
}

.non-surgical {
  background: #020C16 url(#{$imagesPath}non-surgical-bg@2x.jpg) center center/cover no-repeat;
  background-size: contain;
  @include media-mobile-only {
    padding: 20*$pxToRem 0 0;
    background: #020C16;
  }
}
@media screen and (max-width: 1600px) {
  .non-surgical {
    background-position-x: 30%;
    background-size: cover;
  }
}

.nav-floating {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 1100px) {
    position: relative;
    display: block !important;
  }
  .nav {
    background: $color-white;
    padding: 20*$pxToRem 60*$pxToRem;
  }
  &-center {
    display: flex;
    align-items: center;
  }
  &-top {
    bottom: auto;
  }
  &-bottom {
    top: auto;
  }
  ul {
    display: block;
    li {
      margin: 1rem 0;
    }
  }
  .bg-purple-left {
    z-index: 2;
    &::after {
      transform: translateX(-1.5rem) translateY(1.5rem);
      @media screen and (max-width: 1100px) {
        display: none;
      }
    }
  }
}


.consultation-bar {
  background: #020C16 url(#{$imagesPath}contact-bg@2x.jpg) center center/cover no-repeat;
  h2 {
    a {
      position: relative;
      color: $color-white;
      padding: 2rem 0;
      &::before, &::after {
        display: block;
        content: "";
        position: absolute;
        background-color: $color-accent;
        left: 0;
        right: 0;
        width: 50%;
        height: 1px;
        z-index: -1;
        transition: transform $transition-default;
        margin: auto;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
      &:hover {
        color: $color-accent;
        &::before {
          transform: translateY(-6px)
        }
        &::after {
          transform: translateY(6px)
        }
      }
    }
  }
}

.breast-links ul {
  gap: 2.0625vw;
}




// Tabs
.container {
  margin: 2rem;
}
#tabs {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media-mobile-only {
    align-items: center;
    margin-bottom: 10*$pxToRem;
  }
  li {
    display: inline-block;
    margin-bottom: -1px;
    a {
      color: rgba($color-white,.4);
      display: block;
      height: 100%;
      padding: 10px;
      text-align: left;
      font-size: clamp(35*$pxToRem, 50*$pxToRem, 4vw);
      font-weight: 300;
      font-family: 'futura-pt', sans-serif;
      @include media-mobile-only {
        text-align: center;
      }
    }
    &:hover a,
    &.active a {
      color: $color-white;
    }
  }
}

#tab-contents {
  .tab-contents {
    padding: 10px;
    display: none;
    color: $color-white;
    &.active {
      display: block;
    }
  }
}
